import React from "react";

const ReportsTableHeader = ({
  reportType = "attendance",
  includeDate = false,
  includeIntervention = false,
  includeTeacher = false,
  includeAbsent = false,
  includeAssessment = false,
  includeMastered = false,
  includeScore = false,
  includeNotes = false,
  includeTotalAbsences = false,
  includeTardy = false,
  includeCapacity = false,
  includePrivacy = false,
  includeRequired = false,
}) => {
  return (
    <thead className="contents subgrid">
      <tr className="contents subgrid">
        {includeDate && (
          <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
            <span>Date</span>
          </th>
        )}
        {includeIntervention && (
          <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
            <span>Intervention</span>
          </th>
        )}
        {includeTeacher && (
          <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
            <span>Teacher</span>
          </th>
        )}
        <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
          {reportType === "unscheduled-teachers" ||
          reportType === "interventions-only" ? (
            <span>Teacher name</span>
          ) : (
            <span>Student name</span>
          )}
        </th>
        {includeRequired && (
          <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
            <span>Required</span>
          </th>
        )}
        {includeAbsent && (
          <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
            <span>Absent</span>
          </th>
        )}
        {includeTardy && (
          <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
            <span>Tardy</span>
          </th>
        )}
        {includeAssessment && (
          <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
            <span>Assessment Taken</span>
          </th>
        )}
        {includeMastered && (
          <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
            <span>Mastered Skill</span>
          </th>
        )}
        {includeScore && (
          <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
            Score
          </th>
        )}
        {includeNotes && (
          <th className="border-grayprimary-500 border-b-4 border-l p-2.5 grid place-items-center">
            Notes
          </th>
        )}
        {includeTotalAbsences && (
          <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
            Total Absences
          </th>
        )}
        {includeCapacity && (
          <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
            Attendees
          </th>
        )}
        {includeCapacity && (
          <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
            Capacity
          </th>
        )}
        {includePrivacy && (
          <th className="border-grayprimary-500 border-b-4 p-2.5 grid place-items-center">
            Privacy
          </th>
        )}
      </tr>
    </thead>
  );
};
export default ReportsTableHeader;
