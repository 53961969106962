import React, { useState, useContext, useEffect } from "react";
import { StateContext } from "../contexts/State";
import Navbar from "../components/layouts/Navbar";
import Hamburger from "../components/svg/Hamburger";
import { useCallback } from "react";
import { BarLoader } from "react-spinners";
import ReportTableBody from "../components/reports/ReportTableBody";
import ReportsTableHeader from "../components/reports/ReportsTableHeader";

/**
 * Attendance Page
 * Let teachers record attendance
 * @returns Attendance Page
 */
const Reports = (props) => {
  const currentDate = new Date();
  const todaysDate = (function () {
    let month = String(currentDate.getMonth() + 1).padStart(2, "0");
    let day = String(currentDate.getDate()).padStart(2, "0");
    return `${currentDate.getFullYear()}-${month}-${day}`;
  })();
  const lastWeekDate = (function () {
    let month = String(
      currentDate.getDate() - 7 > 0
        ? currentDate.getMonth() + 1
        : currentDate.getMonth()
    ).padStart(2, "0");
    let fullDate = new Date(
      currentDate.getFullYear(),
      month,
      currentDate.getDate() - 7
    );
    let year = currentDate.getFullYear();
    if (month === "00" && currentDate.getDate() <= 7) {
      month = "12";
      year--;
    }
    let day = String(fullDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  })();

  const { state } = useContext(StateContext);
  const [attendanceReport, setAttendanceReport] = useState([]);
  const [showNavbar, setShowNavbar] = useState(false);
  let [teachersMap, setTeachersMap] = useState({});

  // const [startDate, setStartDate] = useState(lastWeekDate);
  const [startDate, setStartDate] = useState(todaysDate);
  const [endDate, setEndDate] = useState(todaysDate);
  const [teacher, setTeacher] = useState("");
  const [student, setStudent] = useState("");
  const [reportType, setReportType] = useState("attendance");
  const [includeDate, setIncludeDate] = useState(true);
  const [includeIntervention, setIncludeIntervention] = useState(true);
  const [includeTeacher, setIncludeTeacher] = useState(true);
  const [includeAbsent, setIncludeAbsent] = useState(true);
  const [includeAssessment, setIncludeAssessment] = useState(true);
  const [includeMastered, setIncludeMastered] = useState(true);
  const [includeScore, setIncludeScore] = useState(true);
  const [includeNotes, setIncludeNotes] = useState(true);
  const [includeTotalAbsences, setIncludeTotalAbsences] = useState(false);
  const [includeTardy, setIncludeTardy] = useState(true);
  const [includeCapacity, setIncludeCapacity] = useState(false);
  const [includePrivacy, setIncludePrivacy] = useState(false);
  const [includeRequired, setIncludeRequired] = useState(false);
  const [apiResponse, setApiResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const checkDisplay =
    reportType === "attendance" ? "show-checkbox" : "hide-checkbox";

  const getInterventions = useCallback(async () => {
    const res = await fetch(
      process.env.REACT_APP_API_URL +
        `interventions/${startDate.replace(/-/g, "/")}/${endDate.replace(
          /-/g,
          "/"
        )}`,
      { headers: { Authorization: "Bearer " + state.currentUser.token } }
    );
    return await res.json();
  }, [state.currentUser.token, startDate, endDate]);

  const getUnscheduledStudents = useCallback(async () => {
    const res = await fetch(
      process.env.REACT_APP_API_URL +
        `unscheduled_students/${startDate.replace(/-/g, "/")}/${endDate.replace(
          /-/g,
          "/"
        )}`,
      { headers: { Authorization: "Bearer " + state.currentUser.token } }
    );
    return await res.json();
  }, [state.currentUser.token, startDate, endDate]);

  const getUnscheduledTeachers = useCallback(async () => {
    const res = await fetch(
      process.env.REACT_APP_API_URL +
        `unscheduled_teachers/${startDate.replace(/-/g, "/")}/${endDate.replace(
          /-/g,
          "/"
        )}`,
      { headers: { Authorization: "Bearer " + state.currentUser.token } }
    );
    return await res.json();
  }, [state.currentUser.token, startDate, endDate]);

  useEffect(() => {
    if (!state?.currentUser?.token) return;
    (async () => {
      if (reportType === "attendance") {
        setLoading(true);
        const res = await getInterventions();
        setApiResponse(res);
        setLoading(false);
      } else if (reportType === "unscheduled-students") {
        setLoading(true);
        let res = await getUnscheduledStudents();
        res = res.sort((a, b) =>
          a.last_name.toLowerCase() > b.last_name.toLowerCase() ? 1 : -1
        );
        setApiResponse(res);
        setLoading(false);
      } else if (reportType === "unscheduled-teachers") {
        setLoading(true);
        let res = await getUnscheduledTeachers();
        res = res.sort((a, b) =>
          a.last_name.toLowerCase() > b.last_name.toLowerCase() ? 1 : -1
        );
        setApiResponse(res);
        setLoading(false);
      } else if (reportType === "interventions-only") {
        setLoading(true);
        const res = await getInterventions();
        setApiResponse(res);
        setLoading(false);
      }
    })();
  }, [
    getInterventions,
    getUnscheduledStudents,
    getUnscheduledTeachers,
    reportType,
    state?.currentUser?.token,
  ]);

  const populateReport = useCallback(async () => {
    try {
      let report;
      if (reportType === "attendance") {
        setIncludeDate(true);
        setIncludeIntervention(true);
        setIncludeNotes(true);
        setIncludeScore(true);
        setIncludeTeacher(true);
        setIncludeAbsent(true);
        setIncludeTardy(true);
        setIncludeMastered(true);
        setIncludeAssessment(true);
        setIncludeTotalAbsences(false);
        setIncludeCapacity(false);
        setIncludePrivacy(false);
        setIncludeRequired(false);
        // let json = await getInterventions();
        let json = apiResponse;
        report = json?.interventions_found?.filter((intervention) => {
          const regEx = new RegExp(`.*${teacher.toLowerCase()}.*`);
          const teacherName = teachersMap[intervention.owner];

          return teacherName?.toLowerCase().match(regEx) != null;
        });
        report = report?.map((intervention) => {
          return {
            ...intervention,
            attendees: intervention.attendees?.map((attendee) => {
              const { attendees, ...rest } = intervention;
              return { ...attendee, intervention: rest };
            }),
          };
        });

        report = report?.map((intervention) => {
          intervention.attendees = intervention?.attendees.filter(
            (attendee) => {
              const regEx = new RegExp(`.*${student.toLowerCase()}.*`);
              const attendeeName =
                `${attendee?.last_name}, ${attendee?.first_name}`.toLowerCase();

              return attendeeName?.toLowerCase().match(regEx) !== null;
            }
          );
          return intervention;
        });
        report = report?.sort((a, b) => {
          if (a.date > b.date) {
            return -1;
          } else if (a.date === b.date) {
            if (a.owner_name === b.owner_name) {
              return a.name.localeCompare(b.name);
            } else {
              return a.owner_name.localeCompare(b.owner_name);
            }
          } else {
            return 1;
          }
        });
      } else if (reportType === "interventions-only") {
        let json = apiResponse;
        report = json?.interventions_found?.filter((intervention) => {
          const regEx = new RegExp(`.*${teacher.toLowerCase()}.*`);
          const teacherName = teachersMap[intervention.owner];

          return teacherName?.toLowerCase().match(regEx) != null;
        });

        report = report?.sort((a, b) => {
          if (a.date > b.date) {
            return -1;
          } else if (a.date === b.date) {
            if (a.owner_name === b.owner_name) {
              return a.name.localeCompare(b.name);
            } else {
              return a.owner_name.localeCompare(b.owner_name);
            }
          } else {
            return 1;
          }
        });
        setIncludeDate(true);
        setIncludeIntervention(true);
        setIncludeNotes(false);
        setIncludeRequired(false);
        setIncludeScore(false);
        setIncludeTeacher(false);
        setIncludeAbsent(false);
        setIncludeTardy(false);
        setIncludeMastered(false);
        setIncludeAssessment(false);
        setIncludeCapacity(true);
        setIncludePrivacy(false);
      } else {
        // let json = await getUnscheduledStudents();
        let json = apiResponse;
        if (json.length) {
          report = json?.filter((attendee) => {
            const regEx = new RegExp(`.*${student.toLowerCase()}.*`);
            const attendeeName =
              `${attendee.last_name}, ${attendee.first_name}`.toLowerCase();

            return attendeeName?.toLowerCase().match(regEx) !== null;
          });
        }
        setIncludeDate(false);
        setIncludeIntervention(false);
        setIncludeNotes(false);
        setIncludeRequired(false);
        setIncludeScore(false);
        setIncludeTeacher(false);
        setIncludeAbsent(false);
        setIncludeTardy(false);
        setIncludeMastered(false);
        setIncludeAssessment(false);
        setIncludeTotalAbsences(false);
        setIncludeCapacity(false);
        setIncludePrivacy(false);
      }

      setAttendanceReport(report);
    } catch (error) {
      console.error(error);
    }
  }, [reportType, student, teacher, teachersMap, apiResponse]);

  // Get teachers list
  useEffect(() => {
    if (!state.currentUser.token) return;
    (async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}all_users`, {
          headers: {
            Authorization: "Bearer " + state.currentUser.token,
          },
        });
        let json = await res.json();

        const map = {};
        json.forEach((user) => {
          if (user.role === 2 || user.role === 1) {
            map[user.id] = user.last_name + ", " + user.first_name;
          }
        });
        setTeachersMap(map);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [state.currentUser.token]);

  // Get interventions
  useEffect(() => {
    if (!state.currentUser.token) return;
    if (!apiResponse) return;
    populateReport();
  }, [state.currentUser.token, populateReport, apiResponse]);

  function downloadCSV(e) {
    let csvContent = "";

    var rows = document.getElementsByTagName("tr");
    for (const row of rows) {
      var cols = row.querySelectorAll("td,th");

      for (const col of cols) {
        let input;
        if ((input = col.querySelector("input"))) {
          csvContent += input.checked + ",";
        } else {
          csvContent += '"' + col.innerText + '"' + ",";
        }
      }
      csvContent += "\n";
    }

    e.target.href = URL.createObjectURL(
      new Blob([csvContent], { type: "text/csv" })
    );
    e.target.download =
      reportType + "-" + startDate + "-to-" + endDate + ".csv";
  }

  function print() {
    window.print();
  }

  return (
    <section className="reports-page">
      <Navbar open={showNavbar} />
      <main
        className="dashboard-content h-screen"
        onClick={() => {
          if (showNavbar) {
            setShowNavbar(false);
          }
        }}
      >
        <Hamburger
          className="hamburger-icon px-4 py-4 h-10 w-10"
          onClick={() => {
            setShowNavbar(true);
          }}
        />
        <header className="flex flex-row items-center">
          <h2 className="text-3xl my-4">Print reports</h2>
          <a
            onClick={downloadCSV}
            className="block bg-blueprimary-500 text-white px-4 py-1.5 rounded-md shadow-sm ml-auto"
          >
            Download CSV
          </a>
          <button
            className="block bg-blueprimary-500 text-white px-4 py-1.5 rounded-md shadow-sm ml-4"
            onClick={print}
          >
            Print/Get PDF
          </button>
        </header>
        <section className="flex flex-wrap">
          <fieldset className="mr-6">
            <h3 className="text-2xl mb-2 border-b border-gray-200">
              Date range
            </h3>
            From{" "}
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="border-b border-gray-200"
            />{" "}
            to{" "}
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="border-b border-gray-200"
            />
          </fieldset>
          <fieldset className="mr-6">
            <h3 className="text-2xl mb-2 border-b border-gray-200">
              Filter by Teacher
            </h3>
            <input
              list="teacher-select-options"
              className="border-b border-gray-200 bg-transparent pr-4"
              onChange={(e) => setTeacher(e.target.value)}
            />
            <datalist id="teacher-select-options">
              {Object.keys(teachersMap).map((teacherId, key) => (
                <option key={key} value={teachersMap[teacherId]} />
              ))}
            </datalist>
          </fieldset>
          <fieldset className="mr-6">
            <h3 className="text-2xl mb-2 border-b border-gray-200">
              Filter by Student
            </h3>
            <input
              list="student-select-options"
              className="border-b border-gray-200 bg-transparent pr-4"
              onChange={(e) => setStudent(e.target.value)}
            />
            <datalist id="student-select-options">
              {attendanceReport?.map((intervention) =>
                intervention?.attendees?.map((attendee, key) => (
                  <option
                    key={key}
                    value={`${attendee?.last_name}, ${attendee?.first_name}`}
                  />
                ))
              )}
            </datalist>
          </fieldset>
          <fieldset className="mr-6">
            <h3 className="text-2xl mb-2 border-b border-gray-200">
              Report Type
            </h3>
            <select
              value={reportType}
              className="border-b border-gray-200 bg-transparent pr-4"
              onChange={(e) => setReportType(e.target.value)}
            >
              <option value="attendance">Attendance Report</option>
              <option value="unscheduled-students">Unscheduled Students</option>
              {state.currentUser.user?.role === 1 && (
                <option value="unscheduled-teachers">
                  Unscheduled Teachers
                </option>
              )}
              <option value="interventions-only">Teacher Interventions</option>
            </select>
          </fieldset>

          {reportType === "interventions-only" && (
            <fieldset style={{ flexBasis: "100%" }}>
              <h3 className={` text-2xl mb-2 border-b border-gray-200 mt-6`}>
                Fields to Include
              </h3>
              <div className={` grid report-controls-grid mb-6`}>
                <label>
                  <input
                    type="checkbox"
                    defaultChecked={includeTotalAbsences}
                    onChange={(e) => setIncludeTotalAbsences(e.target.checked)}
                  />{" "}
                  Total Absences
                </label>
                <label>
                  <input
                    type="checkbox"
                    defaultChecked={true}
                    onChange={(e) => setIncludeCapacity(e.target.checked)}
                  />{" "}
                  Capacity
                </label>
                <label>
                  <input
                    type="checkbox"
                    defaultChecked={includePrivacy}
                    onChange={(e) => setIncludePrivacy(e.target.checked)}
                  />{" "}
                  Private
                </label>
              </div>
            </fieldset>
          )}
          <fieldset style={{ flexBasis: "100%" }}>
            <h3
              className={`${checkDisplay} text-2xl mb-2 border-b border-gray-200 mt-6`}
            >
              Fields to Include
            </h3>
            <div className={`${checkDisplay} grid report-controls-grid mb-6`}>
              <label>
                <input
                  type="checkbox"
                  defaultChecked={includeDate}
                  // checked={includeDate}
                  onChange={(e) => setIncludeDate(e.target.checked)}
                />{" "}
                Date
              </label>
              <label>
                <input
                  type="checkbox"
                  defaultChecked={includeIntervention}
                  // checked={includeIntervention}
                  onChange={(e) => setIncludeIntervention(e.target.checked)}
                />{" "}
                Intervention
              </label>
              <label>
                <input
                  type="checkbox"
                  defaultChecked={includeTeacher}
                  // checked={includeTeacher}
                  onChange={(e) => setIncludeTeacher(e.target.checked)}
                />{" "}
                Teacher
              </label>
              <label>
                <input
                  type="checkbox"
                  defaultChecked={includeRequired}
                  // checked={includeTeacher}
                  onChange={(e) => setIncludeRequired(e.target.checked)}
                />{" "}
                Required Attendee
              </label>
              <label>
                <input
                  type="checkbox"
                  defaultChecked={includeAbsent}
                  // checked={includeAbsent}
                  onChange={(e) => setIncludeAbsent(e.target.checked)}
                />{" "}
                Absent
              </label>
              <label>
                <input
                  type="checkbox"
                  defaultChecked={includeTardy}
                  onChange={(e) => setIncludeTardy(e.target.checked)}
                />{" "}
                Tardy
              </label>
              <label>
                <input
                  type="checkbox"
                  defaultChecked={includeAssessment}
                  // checked={includeAssessment}
                  onChange={(e) => setIncludeAssessment(e.target.checked)}
                />{" "}
                Assessment Taken
              </label>
              <label>
                <input
                  type="checkbox"
                  defaultChecked={includeMastered}
                  // checked={includeMastered}
                  onChange={(e) => setIncludeMastered(e.target.checked)}
                />{" "}
                Skill Mastered
              </label>
              <label>
                <input
                  type="checkbox"
                  defaultChecked={includeScore}
                  // checked={includeScore}
                  onChange={(e) => setIncludeScore(e.target.checked)}
                />{" "}
                Score
              </label>
              <label>
                <input
                  type="checkbox"
                  defaultChecked={includeNotes}
                  // checked={includeNotes}
                  onChange={(e) => setIncludeNotes(e.target.checked)}
                />{" "}
                Notes
              </label>
            </div>
          </fieldset>
        </section>
        {loading ? (
          <>
            <div className="loader flex flex-col items-center gap-4">
              <h2 className="mb-4 text-blueprimary-600 text-3xl">Loading...</h2>
              <BarLoader color="#4383cb" />
            </div>
          </>
        ) : (
          <table
            className="grid attendance-grid border-grayprimary-500 border-b-transparent border shadow-md rounded-md"
            style={{
              gridTemplateColumns: `repeat(${
                includeDate +
                includeIntervention +
                includeTeacher +
                1 + // name
                includeAbsent +
                includeTardy +
                includeAssessment +
                includeMastered +
                includeScore +
                includeTotalAbsences +
                includePrivacy +
                includeRequired +
                (includeCapacity ? 2 : 0)
              }, auto) ${includeNotes ? "minmax(4em, 1fr)" : ""}`,
            }}
          >
            <ReportsTableHeader
              reportType={reportType}
              includeDate={includeDate}
              includeIntervention={includeIntervention}
              includeTeacher={includeTeacher}
              includeAbsent={includeAbsent}
              includeTardy={includeTardy}
              includeAssessment={includeAssessment}
              includeMastered={includeMastered}
              includeScore={includeScore}
              includeNotes={includeNotes}
              includeRequired={includeRequired}
              includeTotalAbsences={includeTotalAbsences}
              includeCapacity={includeCapacity}
              includePrivacy={includePrivacy}
            />
            <ReportTableBody
              attendanceReport={attendanceReport}
              reportType={reportType}
              teachersMap={teachersMap}
              includeDate={includeDate}
              includeIntervention={includeIntervention}
              includeTeacher={includeTeacher}
              includeAbsent={includeAbsent}
              includeTardy={includeTardy}
              includeAssessment={includeAssessment}
              includeMastered={includeMastered}
              includeScore={includeScore}
              includeNotes={includeNotes}
              includeRequired={includeRequired}
              includeTotalAbsences={includeTotalAbsences}
              includeCapacity={includeCapacity}
              includePrivacy={includePrivacy}
            />
          </table>
        )}
      </main>
    </section>
  );
};
export default Reports;
