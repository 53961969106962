import React from "react";

const ReportTableBody = ({
  attendanceReport = [],
  reportType = "attendance",
  teachersMap = [],
  includeDate = false,
  includeIntervention = false,
  includeTeacher = false,
  includeAbsent = false,
  includeAssessment = false,
  includeMastered = false,
  includeScore = false,
  includeNotes = false,
  includeTotalAbsences = false,
  includeTardy = false,
  includeCapacity = false,
  includePrivacy = false,
  includeRequired = false,
}) => {
  const totalAbsences = (attendees) => {
    let absences = 0;
    if (attendees?.length > 0) {
      for (let i = 0; i < attendees.length; i++) {
        if (!attendees[i]?.attended) {
          absences += 1;
        }
      }
    }
    return absences;
  };
  if (reportType === "attendance") {
    return (
      <tbody className="contents subgrid">
        {attendanceReport?.map((intervention, i) =>
          intervention?.attendees?.map((attendee, i) => (
            <tr key={i} className="contents subgrid">
              {includeDate && (
                <td
                  className={`${
                    reportType === "interventions-only" ? "text-center" : ""
                  } border-grayprimary-500 border-b p-2.5`}
                >
                  <span>{intervention?.date}</span>
                </td>
              )}
              {includeIntervention && (
                <td
                  className={`${
                    reportType === "interventions-only" ? "text-center" : ""
                  } border-grayprimary-500 border-b p-2.5`}
                >
                  <span>{intervention?.name}</span>
                </td>
              )}
              {includeTeacher && (
                <td className="border-grayprimary-500 border-b p-2.5">
                  <span>{teachersMap[intervention?.owner]}</span>
                </td>
              )}
              <td
                className={`${
                  reportType === "interventions-only" ? "text-center" : ""
                } border-grayprimary-500 border-b p-2.5`}
              >
                {reportType === "interventions-only" ? (
                  <span>{teachersMap[intervention?.owner]}</span>
                ) : (
                  <span>
                    {attendee.last_name}, {attendee.first_name}
                  </span>
                )}
              </td>
              {includeRequired && (
                <td className="border-grayprimary-500 border-b p-2.5 text-center">
                  <input
                    type="checkbox"
                    checked={attendee.required}
                    onClick={(e) => e.preventDefault()}
                    readOnly
                  />
                </td>
              )}
              {includeAbsent && (
                <td className="border-grayprimary-500 border-b p-2.5 text-center">
                  <input
                    type="checkbox"
                    checked={attendee.attended}
                    onClick={(e) => e.preventDefault()}
                    readOnly
                  />
                </td>
              )}
              {includeTardy && (
                <td className="border-grayprimary-500 border-b p-2.5 text-center">
                  <input
                    type="checkbox"
                    checked={attendee.tardy}
                    onClick={(e) => e.preventDefault()}
                    readOnly
                  />
                </td>
              )}
              {includeAssessment && (
                <td className="border-grayprimary-500 border-b p-2.5 text-center">
                  <input
                    type="checkbox"
                    name="assessment"
                    checked={attendee.assessment}
                    onClick={(e) => e.preventDefault()}
                    readOnly
                  />
                </td>
              )}
              {includeMastered && (
                <td className="border-grayprimary-500 border-b p-2.5 text-center">
                  <input
                    type="checkbox"
                    name="mastered"
                    checked={attendee.mastered}
                    onClick={(e) => e.preventDefault()}
                    readOnly
                  />
                </td>
              )}
              {includeScore && (
                <td className="border-grayprimary-500 border-b p-2.5 text-center">
                  <span>{attendee.score}</span>
                </td>
              )}
              {includeNotes && (
                <td className="border-grayprimary-500 border-b border-l p-2.5 attendance-grid__notes">
                  <span>{attendee.notes}</span>
                </td>
              )}
              {includeTotalAbsences && (
                <td className="border-grayprimary-500 border-b p-2.5 text-center">
                  <span>{intervention?.total_absences}</span>
                </td>
              )}
            </tr>
          ))
        )}
      </tbody>
    );
  } else {
    return (
      <tbody className="contents subgrid">
        {attendanceReport?.map((intervention, i) => (
          <tr key={i} className="contents subgrid">
            {includeDate && (
              <td className="border-grayprimary-500 border-b p-2.5 text-center">
                <span>{intervention?.date}</span>
              </td>
            )}
            {includeIntervention && (
              <td
                className={`${
                  reportType === "interventions-only" ? "text-center" : ""
                } border-grayprimary-500 border-b p-2.5`}
              >
                <span>{intervention?.name}</span>
              </td>
            )}
            {includeTeacher && (
              <td className="border-grayprimary-500 border-b p-2.5">
                <span>{teachersMap[intervention?.owner]}</span>
              </td>
            )}
            <td
              className={`${
                reportType === "interventions-only" ? "text-center" : ""
              } border-grayprimary-500 border-b p-2.5`}
            >
              {/* If unscheduled student report, this will be student name. Otherwise, teacher name. */}
              {reportType === "interventions-only" ? (
                <span>{teachersMap[intervention?.owner]}</span>
              ) : (
                <span>
                  {intervention.last_name}, {intervention.first_name}
                </span>
              )}
            </td>
            {includeTotalAbsences && (
              <td className="border-grayprimary-500 border-b p-2.5 text-center">
                <span>{totalAbsences(intervention?.attendees)}</span>
              </td>
            )}
            {includeCapacity && (
              <td className="border-grayprimary-500 border-b p-2.5 text-center">
                <span>{intervention?.attendees?.length}</span>
              </td>
            )}
            {includeCapacity && (
              <td className="border-grayprimary-500 border-b p-2.5 text-center">
                <span>{intervention?.capacity}</span>
              </td>
            )}
            {includePrivacy && (
              <td className="border-grayprimary-500 border-b p-2.5 text-center">
                <span>{intervention?.private ? 'Private' : 'Open'}</span>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    );
  }
};
export default ReportTableBody;
