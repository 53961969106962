import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { StateContext } from "../../contexts/State";
import Button from "../Button";
import NavbarLink from "./NavbarLink";

/**
 *
 * @param children is the content that should be showed on the right side of the screen
 * @returns
 */
const Navbar = ({ open, children }) => {
  const { state, dispatch } = useContext(StateContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const showRole = () => {
    // 1 - admin, 2 - teacher, 3 - student, 4 - staff
    const role = state.currentUser?.user?.role;
    if (role === 1) {
      return "Admin";
    } else if (role === 2) {
      return "Teacher";
    } else if (role === 3) {
      return "Student";
    } else if (role === 4) {
      return "Staff";
    } else return "";
  };

  return (
    <div className={`navbar flex items-center md:justify-between justify-center px-2 sm:px-24 text-center mt-8 ${open ? "open" : ""}`}>
      <Link to={"/"} className="nav-logo">
        <img src="/tss-nav.png" alt="tss logo" />
      </Link>

      <div className="flex items-center gap-4">
        <div className="bg-tssBlue-200 flex items-center gap-1 rounded-3xl p-1">
          <NavbarLink to={"/dashboard"}>Calendar</NavbarLink>
          {state.currentUser?.user?.role !== 3 && ( // Not student
            <NavbarLink to={"/reports"}>Reports</NavbarLink>
          )}
          {state.currentUser?.user?.role === 1 && ( // only admin
            <>
              <NavbarLink to={"/csv-upload"}>CSV Upload</NavbarLink>
              <NavbarLink to={"/admin"}>Add/Edit Users</NavbarLink>
              <NavbarLink to={"/block-off-times"}>Block Times</NavbarLink>
            </>
          )}
        </div>
        <div
          className="relative"
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
        >
          <img
            src="/profile-img.png"
            alt="profile and settings"
            className="cursor-pointer"
          />
          {showDropdown && (
            <div className="w-max flex flex-col gap-2 absolute border-[1.5px] border-tssGray-500 rounded-2xl px-4 py-2 bg-white z-40 right-0 mt-2  cursor-default">
              <div className="border-b-tssGray-500 border-b-[1.5px] text-right pb-2">
                <span className="block text-base w-full text-tssBlack-500">
                  {state.currentUser?.user?.last_name}{" "}
                  {state.currentUser?.user?.first_name}
                </span>
                <span className="ml-1 text-sm text-tssGray-600 italic">
                  {state.currentUser?.user?.school_name}
                </span>
                <p className="ml-1 text-sm text-tssGray-600">{showRole()}</p>
              </div>
              {state.currentUser?.user?.role !== 3 && ( // Not student
                <NavbarLink
                  className={"hover:text-tssBlack-500"}
                  to={"/settings"}
                >
                  Settings
                </NavbarLink>
              )}
              <button
                id=""
                className="font-normal text-sm text-tssGray-600 hover:text-tssBlack-500"
                type="button"
                onClick={async () => {
                  navigate("/login");
                  await dispatch({ type: "REMOVE_ALL_DATA" });
                  localStorage.removeItem("currentUser");
                  document.cookie =
                    "tss_remember_me=keep_logged_in; max-age=0; SameSite=lax; path=/";
                }}
              >
                Logout
              </button>
            </div>
          )}
        </div>
        {showDropdown && (
          <div
            className="w-full h-full z-20 absolute"
            onClick={() => {
              setShowDropdown(false);
            }}
          ></div>
        )}
      </div>
    </div>
  );
};
export default Navbar;
